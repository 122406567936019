<mat-card class="solution-card text-center">
  <img mat-card-image [src]="src" [alt]="altText" class="solution-card-img">
  <mat-card-content>
    <p>
      {{ title }}
    </p>
    <p class="text-muted">
      {{ detail }}
    </p>
  </mat-card-content>
  <!-- <mat-card-actions>
    <a mat-stroked-button color="primary" [routerLink]="detailLink">
      learn more
    </a>
  </mat-card-actions> -->
</mat-card>
