<div class="row mb-5 margin-up">
  <app-solution class="col-6 col-md-3"
    src="/assets/img/cogs.svg"
    altText="Cogs icon"
    title="Task automation"
    detail="Simplify daily routines by automating them.">
  </app-solution>

  <app-solution class="col-6 col-md-3"
    src="/assets/img/python.svg"
    altText="Python logo icon"
    title="Scripting"
    detail="Simple solutions by scripting">
  </app-solution>

  <app-solution class="col-6 col-md-3"
    src="/assets/img/AI_rect.svg"
    altText="AI icon"
    title="AI solutions"
    detail="Artificial Inteligence solutions. From regression to object detection.">
  </app-solution>

  <app-solution class="col-6 col-md-3"
    src="/assets/img/big_data_2.svg"
    altText="Big data icon"
    title="Big data"
    detail="Pipelines, data storage and API's">
  </app-solution>
</div>
