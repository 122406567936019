import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-solution',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.scss']
})
export class SolutionComponent implements OnInit {
  @Input()  src: string;
  @Input()  altText: string;
  @Input()  title: string;
  @Input()  detail: string;

  constructor() { }

  ngOnInit(): void {
  }

}
