<div style="height: 100vh;">
  <mat-toolbar color="primary" class="position-sticky sticky-top">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <img src="assets/img/logo.svg" alt="logo" width="150">
        <!-- The following menu items will be hidden on both SM and XS screen sizes -->
        <div fxShow="true" fxHide.lt-md="true">
            <a *ngFor="let l of links;" mat-button [routerLink]="l.route" [routerLinkActive]="l.active ? 'active' : ''">
              {{ l.name }}
            </a>
        </div>
        <!-- <div fxShow="true" fxHide.gt-sm="true">
          <button mat-icon-button color="white" aria-label="Menu button" (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
        </div> -->
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="view-height sidenav">
    <!-- content gets "mat-app-background" color -->
    <mat-sidenav #sidenav fxLayout="column" fxFlexFill>
      <div fxLayout="column">
        <a *ngFor="let l of links;" [routerLink]="l.route" [routerLinkActive]="l.active ? 'active' : ''" (click)="sidenav.toggle()">
          {{ l.name }}
        </a>
      </div>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
