<mat-toolbar color="primary" class="footer">
  <div class="footer-row container">
    <div class="cell-data cell-img">
      <img src="assets/img/logo_horizontal.svg" class="img" alt="Logo PYRAID CommV" width="150">
    </div>

    <div class="cell-data">
      <h1 class="title">Our details</h1>
        <p class="text">BE 0798.413.235</p>
        <p class="text">PYRAID CommV</p>
    </div>

    <div class="cell-data">
      <h1 class="title">Contact</h1>
      <p>
        <a href="mailto:info@pyraid.be" class="text">info@pyraid.be</a>
      </p>
    </div>
  </div>
</mat-toolbar>
