<div class="container p-4">
  <!-- <h2>Contact</h2> -->

  <!-- <div class="row no-gutters mb-4">
    <div class="business-card business-card--front mx-auto">
      <div class="business-card__logo">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 203 32">
          <defs>
            <style>
              .text {
                fill: #fff;
              }
            </style>
          </defs>
          <title>logo</title>
          <g id="PYRAID">
            <g>
              <polygon class="text" points="49.782 10.511 53.062 14.929 62.968 0 57.145 0 49.782 10.511"/>
              <polygon class="text" points="31.912 0 44.36 19.347 44.36 32 50.451 32 50.451 19.213 38.137 0 31.912 0"/>
              <path class="text" d="M90.53,18.6106a10.1273,10.1273,0,0,0,2.4095-2.0753,8.0112,8.0112,0,0,0,1.5386-2.7448,10.2351,10.2351,0,0,0,.6031-3.615,10.6073,10.6073,0,0,0-.8035-4.2176,8.9609,8.9609,0,0,0-2.3421-3.1464A10.7046,10.7046,0,0,0,88.1867.8031,16.4283,16.4283,0,0,0,83.2338.0667h-14.19V5.1545H82.7655A7.8539,7.8539,0,0,1,87.651,6.4934a4.7507,4.7507,0,0,1,1.74,4.0167,4.7167,4.7167,0,0,1-1.74,3.8829,7.2321,7.2321,0,0,1-4.819,1.4728H69.0436V31.9325H74.6V20.82h7.0272l7.8306,11.113h6.6264l.0009,0-8.6341-12.05A16.3841,16.3841,0,0,0,90.53,18.6106Z"/>
              <path class="text" d="M23.8272,6.36a11.0391,11.0391,0,0,0-2.41-3.3473A11.9072,11.9072,0,0,0,17.6025.8037,17.31,17.31,0,0,0,12.5157.0673H0V5.0882H12.0475a8.26,8.26,0,0,1,5.02,1.4728,5.0106,5.0106,0,0,1,1.8738,4.2844,5.3715,5.3715,0,0,1-1.8074,4.2175,7.94,7.94,0,0,1-5.0858,1.54H0V31.9334H5.555V21.691h6.2921a17.988,17.988,0,0,0,5.0194-.7365,12.2077,12.2077,0,0,0,4.0155-2.0752,9.3977,9.3977,0,0,0,2.7448-3.4142,10.3522,10.3522,0,0,0,1.0038-4.6862,11.1566,11.1566,0,0,0-.8034-4.4183Z"/>
              <polygon class="text" points="102.229 31.933 108.119 31.933 115.079 14.929 112.201 7.297 102.229 31.933"/>
              <path class="text" d="M203,16.0671a16.3525,16.3525,0,0,0-1.2717-6.2929,13.6268,13.6268,0,0,0-3.4808-5.0878,15.3041,15.3041,0,0,0-5.4212-3.4142A18.72,18.72,0,0,0,186,0H175.7585V32H186a18.7746,18.7746,0,0,0,6.8932-1.2051,16.4862,16.4862,0,0,0,5.3547-3.3472,13.9385,13.9385,0,0,0,3.4808-5.0879v0A15.1063,15.1063,0,0,0,203,16.0671Zm-6.6938,4.2844a10.1514,10.1514,0,0,1-5.7554,5.7574,12.6994,12.6994,0,0,1-4.6187.8033H181.381V5.0881h4.5511a13.0492,13.0492,0,0,1,4.6187.8033,9.8184,9.8184,0,0,1,3.48,2.2762,8.8171,8.8171,0,0,1,2.2756,3.4141,11.6089,11.6089,0,0,1,.8035,4.3515A11.7928,11.7928,0,0,1,196.3062,20.3515Z"/>
              <polygon class="text" points="150.004 32 155.625 32 155.625 13.322 155.625 0 150.004 0 150.004 32"/>
              <polygon class="text" points="115.146 0 121.906 19.08 126.859 31.933 132.883 31.933 121.236 0 115.146 0"/>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div> -->
  <div class="row no-gutters">
    <div class="business-card business-card--back mx-auto">
      <div class="business-card__logo">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 203 32">
          <defs>
            <style>
              .text-dark {
                fill: rgba(0, 0, 0, 0.6)
              }
            </style>
          </defs>
          <title>logo</title>
          <g id="PYRAID">
            <g>
              <polygon class="text-dark" points="49.782 10.511 53.062 14.929 62.968 0 57.145 0 49.782 10.511"/>
              <polygon class="text-dark" points="31.912 0 44.36 19.347 44.36 32 50.451 32 50.451 19.213 38.137 0 31.912 0"/>
              <path class="text-dark" d="M90.53,18.6106a10.1273,10.1273,0,0,0,2.4095-2.0753,8.0112,8.0112,0,0,0,1.5386-2.7448,10.2351,10.2351,0,0,0,.6031-3.615,10.6073,10.6073,0,0,0-.8035-4.2176,8.9609,8.9609,0,0,0-2.3421-3.1464A10.7046,10.7046,0,0,0,88.1867.8031,16.4283,16.4283,0,0,0,83.2338.0667h-14.19V5.1545H82.7655A7.8539,7.8539,0,0,1,87.651,6.4934a4.7507,4.7507,0,0,1,1.74,4.0167,4.7167,4.7167,0,0,1-1.74,3.8829,7.2321,7.2321,0,0,1-4.819,1.4728H69.0436V31.9325H74.6V20.82h7.0272l7.8306,11.113h6.6264l.0009,0-8.6341-12.05A16.3841,16.3841,0,0,0,90.53,18.6106Z"/>
              <path class="text-dark" d="M23.8272,6.36a11.0391,11.0391,0,0,0-2.41-3.3473A11.9072,11.9072,0,0,0,17.6025.8037,17.31,17.31,0,0,0,12.5157.0673H0V5.0882H12.0475a8.26,8.26,0,0,1,5.02,1.4728,5.0106,5.0106,0,0,1,1.8738,4.2844,5.3715,5.3715,0,0,1-1.8074,4.2175,7.94,7.94,0,0,1-5.0858,1.54H0V31.9334H5.555V21.691h6.2921a17.988,17.988,0,0,0,5.0194-.7365,12.2077,12.2077,0,0,0,4.0155-2.0752,9.3977,9.3977,0,0,0,2.7448-3.4142,10.3522,10.3522,0,0,0,1.0038-4.6862,11.1566,11.1566,0,0,0-.8034-4.4183Z"/>
              <polygon class="text-dark" points="102.229 31.933 108.119 31.933 115.079 14.929 112.201 7.297 102.229 31.933"/>
              <path class="text-dark" d="M203,16.0671a16.3525,16.3525,0,0,0-1.2717-6.2929,13.6268,13.6268,0,0,0-3.4808-5.0878,15.3041,15.3041,0,0,0-5.4212-3.4142A18.72,18.72,0,0,0,186,0H175.7585V32H186a18.7746,18.7746,0,0,0,6.8932-1.2051,16.4862,16.4862,0,0,0,5.3547-3.3472,13.9385,13.9385,0,0,0,3.4808-5.0879v0A15.1063,15.1063,0,0,0,203,16.0671Zm-6.6938,4.2844a10.1514,10.1514,0,0,1-5.7554,5.7574,12.6994,12.6994,0,0,1-4.6187.8033H181.381V5.0881h4.5511a13.0492,13.0492,0,0,1,4.6187.8033,9.8184,9.8184,0,0,1,3.48,2.2762,8.8171,8.8171,0,0,1,2.2756,3.4141,11.6089,11.6089,0,0,1,.8035,4.3515A11.7928,11.7928,0,0,1,196.3062,20.3515Z"/>
              <polygon class="text-dark" points="150.004 32 155.625 32 155.625 13.322 155.625 0 150.004 0 150.004 32"/>
              <polygon class="text-dark" points="115.146 0 121.906 19.08 126.859 31.933 132.883 31.933 121.236 0 115.146 0"/>
            </g>
          </g>
        </svg>
      </div>
      <ul class="business-card__list">
        <li class="business-card__list-item"><h2>Thomas Vantricht</h2></li>
        <li class="business-card__list-item">0468.32.38.00</li>
        <li class="business-card__list-item">info@pyraid.com</li>
        <li class="business-card__list-item">BExxx.xxx.xxx</li>
        <!-- <li class="business-card__list-item">Roggestraat 29, 9160 Lokeren</li> -->
      </ul>
    </div>
  </div>

</div>
