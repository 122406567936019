<app-header></app-header>

<div class="container">
  <app-solutions></app-solutions>

  <div fxLayoutAlign="center center" class="mb-5">
    <a mat-raised-button color="primary" href="mailto:info@pyraid.be" class="p-4">Contact us</a>
  </div>
</div>

<app-footer></app-footer>
