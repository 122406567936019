import { Component, OnInit } from '@angular/core';
import { ILink } from 'src/app/model/link';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  links: ILink[] = [
    // { route: '/', name: 'Home', active: true },
    // { route: '/about', name: 'About', active: false },
    // { route: '/contact', name: 'Contact', active: false }
  ]

  constructor() { }

  ngOnInit(): void {
  }

  // setActive(idx: number): void {
  //   for (let i = 0; i < this.links.length; i++) {
  //     this.links[i].active = i == idx;
  //   }
  // }
}
